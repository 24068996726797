<template>
<div>

  <el-button type="primary" @click="h5Share">构建发布</el-button>

</div>
</template>

<script>

import { schemaGet } from '../api/schema'

export default {
  name: "Schema",
  data(){
  return{
    schema:''
  }
  },
  mounted() {
    this.initData()
  },
  methods: {
    initData() {
      console.log("Schema")
      schemaGet({}).then((res) => {
        console.log(JSON.stringify(res))
        this.schema = res.data
        window.location.href = res.data
      });
    },
    h5Share() {
      window.location.href = this.schema
    }
  }
}
</script>

<style scoped>

</style>
